import { toast } from "react-toastify";
import {
  SFMCreateApi,
  SFMGetApi,
  SFMUnitUpdateApi,
  SFMUpdateApi,
  SFMbasisupdateApi,
  allotmentUpdateApi,
  formulaListapi,
  formulagetapi,
  testMemoGetParambasisstdApi,
  testMemoGetSamplesetsApi,
} from "../../../services/api";
import {
  getDataFromApi,
  GetTenantDetails,
  postDataFromApi,
  putDataFromApi,
} from "../../../services/commonServices";
import { encryptDataForURL } from "../../../utills/useCryptoUtils";

let testmemoId = 0;

export const handleSFMCreate = async (
  responseData,
  navigate,
  message,
  testMemoId,
  setSaveClicked
) => {
  let payload = {
    sfm_data: {
      fk_commodityid: responseData?.fk_commodity_id,
      fk_lab_id: responseData?.allotment_detail?.testmemo_detail?.fk_lab_id,
      sfm_status: "pending",
      fk_tmid: testMemoId,
      fk_allotment_id: responseData?.allotment_detail?.sa_id,
      tenant: GetTenantDetails(1),
    },
  };
  let res = await postDataFromApi(SFMCreateApi, payload);
  if (res?.data?.status === 200) {
    toast.success(message, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    setTimeout(() => {
      navigate("/verificationList");
    }, 1000);
  } else {
    setSaveClicked(false);
    toast.error(res.message, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
};
export const handleSFMCreateWithoutVerification = async (responseData) => {
  let payload = {
    sfm_data: {
      fk_commodityid: responseData?.fkey_commodity,
      fk_allotment_id: responseData?.sa_id,
      fk_lab_id:
        responseData?.test_memo_detail?.fk_lab_id || responseData?.fk_lab_id,
      sfm_status: "pending",
      fk_tmid: responseData?.fk_testmemo_id,
      tenant: GetTenantDetails(1),
    },
  };
  let res = await postDataFromApi(SFMCreateApi, payload);
};
export const getSFMDetails = async (
  id,
  setFormData,
  setTabOpen,
  setIstavSaveClicked,
  setTestMemoSetData,
  isView
) => {
  try {
    const bodyToPass = {
      sfm_id: id,
    };
    let res = await postDataFromApi(SFMGetApi, bodyToPass);
    if (res?.data?.status === 200) {
      let responseData = res.data.data;
      responseData.commodityName = responseData?.commodity?.cmd_name;
      responseData.jrf_sub_commodity_name =
        responseData?.sub_commodity?.sub_commodity_name;
      responseData.sfm_msfm_no = responseData?.sfm_msfm_no
        ? responseData?.sfm_msfm_no
        : "-";
      if (responseData.sfm_status === "pending") {
        responseData.sfm_dateanalysisstarted =
          responseData?.sample_verification?.sample_dateofverification;
      }
      setFormData({
        0: responseData,
      });
      if (responseData.sfm_status != "pending" || isView) {
        setTabOpen(true);
        setIstavSaveClicked(true);
        getTestMemoSetDetails(
          setTestMemoSetData,
          responseData?.fk_tmid,
          setFormData
        );
        testmemoId = responseData?.fk_tmid;
      }
    }
  } catch (error) { }
};

export const handleSingleDetailsCreateUpdate = async (
  tabIndex,
  paramIndex,
  name,
  parambasisData,
  getSFMParamBasis,
  paramBasisSetData,
  isClick,
  setIsOverlayLoader
) => {
  try {
    let spcode = name.split("_");
    spcode = spcode.length === 2 ? spcode[1] : spcode.slice(1).join("_");
    if (
      !parambasisData[tabIndex]?.[name + "_" + paramIndex + "_" + tabIndex] &&
      parambasisData[tabIndex]?.[name + "_" + paramIndex + "_" + tabIndex] !== 0
    ) {
      if (isClick) {
        toast.error("Please add the value", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      return;
    }
    if (GetTenantDetails(1, 1) === "TPBPL") {
      const inputType =
        paramBasisSetData[paramIndex]?.param_detail?.param_sfm_input_type;
      let regex = "";
      let errMsg = "";
      if (inputType === "alphabet") {
        regex = /^[a-zA-Z\s]+$/;
        errMsg = "Please Enter Valid Alphabetical Value";
      } else if (inputType === "numerical_special") {
        // regex = /^[<>+-]\d+(\.\d+)?$/;
        regex = /^[<>+\-]?\d+(\.\d+)?$/;
        errMsg = "Please Enter Valid Special Numeric Value";

      } else if (inputType === "numerical_alphabet") {
        // regex = /^\d+[a-zA-Z]$/;
        regex = /^[1-4][a-d]$/;
        errMsg = "Please Enter Valid Numeric Alphabetic Value";

      } else {
        regex = /^\d+(\.\d+)?$/;
        errMsg = "Please Enter Valid Numeric Value";

      }
      const value =
        parambasisData[tabIndex]?.[name + "_" + paramIndex + "_" + tabIndex];
      if (!regex.test(value)) {
        toast.error(errMsg || "Please enter valid value", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        return false;
      }
    }
    let inputType = "";

    let payload = {
      sp_id: parambasisData[tabIndex]?.["sp_id_" + paramIndex + "_" + tabIndex],
      spbr_id:
        parambasisData[tabIndex]?.[
        "spbr_id_" + spcode + "_" + paramIndex + "_" + tabIndex
        ],
      smpl_parambasis_data: {
        spbr_tmvalue:
          parambasisData[tabIndex]?.[name + "_" + paramIndex + "_" + tabIndex],
        spbr_lcvalue:
          parambasisData[tabIndex]?.[name + "_" + paramIndex + "_" + tabIndex],
        tenant: GetTenantDetails(1),
      },
    };
    if (GetTenantDetails(1, 1) === "TPBPL") {
      inputType =
        paramBasisSetData[paramIndex]?.param_detail?.param_sfm_input_type;
      var txt =
        parambasisData[tabIndex]?.[name + "_" + paramIndex + "_" + tabIndex];
      // var numb = txt.match(/\d/g);
      // numb = numb ? numb?.join("") : "";
      // numb = numb ? numb : 0;
      let numb = txt.match(/[0-9.]/g);
      numb = numb ? numb.join("") : "";
      numb = numb && /\d/.test(numb) ? numb : 0;

      payload.smpl_parambasis_data = {
        spbr_tmvalue: numb,
        spbr_lcvalue: numb,
        spbr_sfm_input_type_value:
          parambasisData[tabIndex]?.[name + "_" + paramIndex + "_" + tabIndex],
        input_type: inputType,
        tenant: GetTenantDetails(1),
      };
    }
    setIsOverlayLoader(true)
    let res = await putDataFromApi(SFMbasisupdateApi, payload);
    if (res?.data?.status === 200) {
      getSFMParamBasis();
    } else {
      toast.error(res.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }
  catch(ex){}
  finally{
    setIsOverlayLoader(false)
  }
};

export const handleSingleDetailsSPUpdate = async (
  tabIndex,
  paramIndex,
  name,
  parambasisData
) => {
  let payload = {
    sp_id: parambasisData[tabIndex]?.["sp_id_" + paramIndex + "_" + tabIndex],
    smpl_param_data: {
      sp_param_unit:
        parambasisData[tabIndex]?.[name + "_" + paramIndex + "_" + tabIndex],
    },
  };
  let res = await putDataFromApi(SFMUnitUpdateApi, payload);
  if (res?.data?.status === 200) {
  } else {
    toast.error(res.message, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
};

export const getTestMemoSetDetails = async (
  setTestMemoSetData,
  testMemoId,
  setFormData
) => {
  try {
    const bodyToPass = {
      test_memo_id: testMemoId,
      module: "sfm",
    };

    let res = await postDataFromApi(testMemoGetSamplesetsApi, bodyToPass);
    if (res?.data?.status === 200) {
      let responseData = res.data.data;
      setTestMemoSetData(responseData);
      if (responseData.length > 0) {
        let smplValue = responseData[0]?.sample_ids?.[0]?.fk_smpl_detail_id;
        let samplGrp = "";
        if (res.data.data[0]?.groups == "Parameters") {
          samplGrp = "Parameters";
        } else {
          samplGrp = responseData[0]?.groups?.[0]?.group_id;
        }

        setFormData((prevFormData) => {
          return {
            ...prevFormData,
            [1]: {
              ...prevFormData[1],
              ["smpl_detail_smpl_id_0"]: smplValue,
              ["group_id_0"]: samplGrp,
            },
          };
        });
      }
    } else {
      setTestMemoSetData([]);
    }
  } catch (error) { }
};
export const tempTestMemoId = (id) => { };
export const getTestMemoParamBasis = async (
  smpl_inwrd_detail_id,
  group_id,
  tabIndex,
  setParamBasisData,
  setParamBasisSetData,
  setBasisCodeData,
  formData,
  setUpdatedMasterOptions,
  setBasisCodeDataValue
) => {
  try {
    if (GetTenantDetails(1, 1) == "TPBPL") {
      group_id = "Parameters";
    }
    if (!smpl_inwrd_detail_id || !group_id) {
      return [];
    }
    const testmemoId = formData[0].fk_tmid;
    const bodyToPass = {
      smpl_inwrd_detail_id: smpl_inwrd_detail_id,
      tm_id: parseInt(testmemoId), // Harcoded Data by yash
    };
    localStorage.setItem("detailID", encryptDataForURL(smpl_inwrd_detail_id));
    if (group_id !== "Parameters") {
      bodyToPass.group_id = group_id;
    }
    let res = await postDataFromApi(testMemoGetParambasisstdApi, bodyToPass);
    if (res?.data?.status === 200) {
      let responseData = res.data.data;
      responseData = responseData.sort(
        (a, b) => a.sp_param_sequence - b.sp_param_sequence
      );
      setParamBasisSetData(responseData);
      let testMemoData = {};
      let basisDetails = [];
      let basisDetailsValues = [];
      let updateMasterOptions = [];
      responseData.forEach((singleData, index) => {
        testMemoData["sp_id_" + index + "_" + tabIndex] = singleData?.sp_id;
        testMemoData["param_name_" + index + "_" + tabIndex] =
          singleData?.param_detail?.param_name;
        testMemoData["param_unit_" + index + "_" + tabIndex] =
          singleData?.sp_param_unit;
        testMemoData["std_name_" + index + "_" + tabIndex] =
          singleData?.std_detail?.std_name;

        singleData?.basis_detail.map((code, codeIndex) => {
          let basisCode = code.spbr_basiscode;
          basisCode = basisCode.replace(" ", "_");
          basisCode = basisCode.toUpperCase();

          testMemoData[
            "spbr_id_" + basisCode.toLowerCase() + "_" + index + "_" + tabIndex
          ] = code.spbr_id;
          if (GetTenantDetails(1, 1) === "TPBPL") {
            testMemoData[
              "value_" + basisCode.toLowerCase() + "_" + index + "_" + tabIndex
            ] = code.spbr_sfm_input_type_value;
          } else {
            testMemoData[
              "value_" + basisCode.toLowerCase() + "_" + index + "_" + tabIndex
            ] = code.spbr_lcvalue;
          }
          testMemoData[
            "value_" +
            basisCode.toLowerCase() +
            "_icon_" +
            index +
            "_" +
            tabIndex
          ] = code.spbr_lcvalue !== null;

          if (!basisDetails.includes(basisCode)) {
            basisDetails.push(basisCode);
            basisDetailsValues.push('value_' + basisCode.toLowerCase())
          }

        });
        responseData.forEach((singleData, index) => {
          basisDetails.map((singlebase) => {
            if (testMemoData["value_" + singlebase.toLowerCase() + "_" + index + "_" + tabIndex] === undefined) {
              testMemoData["value_" + singlebase.toLowerCase() + "_" + index + "_" + tabIndex] = "N/A";
            }
          })
        })
        const unitspValue = singleData?.param_detail?.param_unit.split(",");
        let unitoptions = [];
        unitspValue.map((opt) => {
          unitoptions.push({
            name: opt,
            id: opt,
          });
        });
        const smlpUnits = {
          model: "param_unit_" + index + "_" + tabIndex,
          data: unitoptions,
        };
        updateMasterOptions.push(smlpUnits);
        setParamBasisData((prevFormData) => {
          return {
            ...prevFormData,
            [tabIndex]: testMemoData,
          };
        });
      });
      setUpdatedMasterOptions(updateMasterOptions);
      setBasisCodeData(basisDetails);
      setBasisCodeDataValue(basisDetailsValues);
    } else {
      return [];
    }
  } catch (error) { }
};

export const handleSFMVerificationMain = async (
  formData,
  navigate,
  setLoading,
  setIsOverlayLoader,
  sectionsField
) => {
  let payload;
  payload = {
    sfm_id: formData[0].sfm_id,
    sfm_data: {
      sfm_status: "completed",
      sfm_expecteddateanalysis: formData[0].sfm_expecteddateanalysis,
      sfm_dateanalysisstarted: formData[0].sfm_dateanalysisstarted,
      sfm_dateanalysiscompleted: formData[0].sfm_dateanalysiscompleted,
      sfm_remarks: formData[0].sfm_remarks,
      sfm_ambient_temp: formData[0].sfm_ambient_temp,
      sfm_borometricpressure: formData[0].sfm_borometricpressure,
      sfm_humidity: formData[0].sfm_humidity,
      tenant: GetTenantDetails(1),
    },
  };
  const notrequired = ['sfm_expecteddateanalysis', 'sfm_remarks', 'sfm_borometricpressure', 'sfm_humidity', 'tenant']
  console.log('sectionsField', sectionsField)
  for (let obj in payload.sfm_data) {
    if (
      (payload["sfm_data"][obj] === undefined ||
        !payload["sfm_data"][obj]) && !notrequired.includes(obj)
    ) {
      const fields = sectionsField.fields.filter((field, index) => {
        console.log('fields', field.name)
        if (field.name === obj) {
          field.label = sectionsField.fields[index]
            ? sectionsField.fields[index].label
            : obj;
          return true;
        }
      });

      let errLabel = fields.length ? fields[0].errorlabel : obj;
      if (obj === "sfm_dateanalysiscompleted") {
        errLabel = "Expected Date of Completion"
      }
      toast.error(obj + "  required", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setIsOverlayLoader(false);
      return;
      // }
    }
  }
  setIsOverlayLoader(true);
  let res = await putDataFromApi(SFMUpdateApi, payload);
  if (res.data && res.data.status === 200) {
    setLoading(false);
    toast.success(res.data.message, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    setTimeout(() => {
      navigate("/SFMList");
    }, 1000);
  } else {
    toast.error(res.message, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
  setIsOverlayLoader(false);
};

export const getallFormulaList = async (setAllformulaList) => {
  try {
    let res = await getDataFromApi(formulaListapi);
    if (res?.data?.status === 200) {
      setAllformulaList(res.data.data);
    }
  } catch (error) { }
};
export const getFormulaFieldData = async (f_id, setFormulafieldsData) => {
  try {
    let res = await postDataFromApi(formulagetapi, { f_id: f_id });
    if (res?.data?.status === 200) {
      setFormulafieldsData(res.data.data);
    }
  } catch (error) { }
};

export const handleAllotUpdate = async (formData) => {
  let payload;
  payload = {
    sa_id: formData[0].fk_allotment_id,
    sample_allotment: {
      status: "completed",
      tenant: GetTenantDetails(1),
    },
  };
  let res;
  res = await putDataFromApi(allotmentUpdateApi, payload);
};
