import { lazy } from "react";
import { Navigate } from "react-router-dom";
import PageNotFound from "../views/PageNotFound.js";
import ProtectedRoute from "./ProtectedRoute.js";
import PublicRoute from "./PublicRoute.js";
import TestReportList from "../views/lms/TestReportList.js";
import ExternalJrf from "../views/lms/ExternalJrf.js";
import ExternalJRFApprovalPage from "../layouts/externalApprovalPage.js";
import Support from "../views/Support.js";

/****Layouts*****/
const FullLayout = lazy(() => import("../layouts/FullLayout.js"));
const Login = lazy(() => import("../layouts/login.js"));
const LoginOTP = lazy(() => import("../layouts/loginOTP.js"));
const ForgotPassword = lazy(() => import("../layouts/forgotPassword.js"));

/***** Pages ****/

const JrfListing = lazy(() => import("../views/lms/JrfListing.js"));
const Jrf = lazy(() => import("../views/lms/jrfReport.js"));
const ModuleHistory = lazy(() => import("../views/lms/moduleHistory.js"));
const TestReportPreview = lazy(() => import("../views/lms/TestReportPreview.js"));
const InwardList = lazy(() => import("../views/lms/InwardList.js"));
const AllotmentList = lazy(() => import("../views/lms/AllotmentList.js"));
const VerificationList = lazy(() => import("../views/lms/VerificationList.js"));
const InwardForm = lazy(() => import("../views/lms/InwardForm.js"));
const InternalCertificate = lazy(() =>
  import("../views/lms/InternalCertificate.js")
);
const SampleVerification = lazy(() =>
  import("../views/lms/SampleVerification")
);
const InwardCheckListForm = lazy(() =>
  import("../views/lms/InwardCheckListForm.js")
);
const SampleAssignment = lazy(() => import("../views/lms/SampleAssignment.js"));

const GroupAssignmentPreview = lazy(() =>
  import("../views/lms/GroupAssignmentPreview.js")
);

const TestMemoPDF = lazy(() => import("../views/lms/TestMemoPDF.js"));
const SFMPDF = lazy(() => import("../views/lms/SFMPDF.js"));
const TestMemo = lazy(() => import("../views/lms/TestMemo.js"));
const Sfm = lazy(() => import("../views/lms/SFM.js"));
const Allotment = lazy(() => import("../views/lms/Allotment.js"));

const TestMemoList = lazy(() => import("../views/lms/TestMemoList.js"));
const SfmList = lazy(() => import("../views/lms/SFMList.js"));
const JRFPdfDownLoad = lazy(() => import("../views/lms/JRFPdfDownLoad.js"));

/*****Routes******/
/*
if you want authenticate route then use private route
else u show use the public route

*/

const ThemeRoutes = [
  {
    path: "/",
    element: <PublicRoute component={Login} />,
  },
  {
    path: "/login",
    element: <PublicRoute component={Login} />,
  },
  {
    path: "/loginOTP",
    element: <PublicRoute component={LoginOTP} />,
  },
  {
    path: "/forgotpassword",
    element: <ForgotPassword />,
  },
  {
    path: "/external-jrf-approval/:JRFToken",
    element: <ExternalJRFApprovalPage />,
  },
  {
    path: "/",
    element: <FullLayout />,
    children: [
      { path: "/", element: <Navigate to="/login" /> },
      {
        path: "/jrfListing",
        exact: true,
        element: <ProtectedRoute component={JrfListing} />,
      },
      {
        path: "/jrfListing/jrf",
        exact: true,
        element: <ProtectedRoute component={Jrf} />,
      },
      {
        path: "/jrfListing/external-jrf",
        exact: true,
        element: <ProtectedRoute component={ExternalJrf} />,
      },
      {
        path: "/jrfListing/inwardForm-checklist",
        exact: true,
        element: <InwardCheckListForm />,
      },

      { path: "/inwardList", exact: true, element: <InwardList /> },
      { path: "/inwardList/inwardForm", exact: true, element: <InwardForm /> },
      {
        path: "/inwardList/groupAssignment",
        exact: true,
        element: <SampleAssignment />,
      },
      {
        path: "/inwardList/groupAssignmentPreview",
        exact: true,
        element: <GroupAssignmentPreview />,
      },
      { path: "/allotmentList", exact: true, element: <AllotmentList /> },
      { path: "/allotmentList/allotment", exact: true, element: <Allotment /> },
      { path: "/verificationList", exact: true, element: <VerificationList /> },
      {
        path: "/verificationList/sampleVerification",
        exact: true,
        element: <SampleVerification />,
      },
      { path: "/module-history", exact: true, element: <ModuleHistory /> },
      

      { path: "/testmemoList/testMemo", exact: true, element: <TestMemo /> },

      { path: "/testmemoList", exact: true, element: <TestMemoList /> },

      { path: "/SFMList/result", exact: true, element: <Sfm /> },
      { path: "/testmemoList/testmemoPDF", exact: true, element: <TestMemoPDF /> },
      { path: "/SFMList/SFMPDF", exact: true, element: <SFMPDF /> },
      { path: "/SfmList", exact: true, element: <SfmList /> },
      { path: "/testReport", exact: true, element: <TestReportList /> },
      { path: "/testReport/preview/:icID", exact: true, element: <TestReportPreview /> },
      {
        path: "/testmemoList/test-results",
        exact: true,
        element: <InternalCertificate />,
      },
      { path: "/jrfListing/jrf-pdf-preview/:JRFId", exact: true, element: <JRFPdfDownLoad /> },
      { path: "/support", exact: true, element: <Support /> },
      {
        path: "*",
        element: <PageNotFound />,
      },
    ],
  },
  {
    path: "/500",
    element: <PageNotFound />,
  },
  {
    path: "*",
    element: <PageNotFound />,
  },
];

export default ThemeRoutes;
